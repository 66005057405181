import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../static/images/logo.svg';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Header = ({ siteTitle }) => (
  <header>
    <div className={'container'}>
      <div className={'top-menu'}>
        <div className={'logo'}>
          <Link to='/' title={'HealthPoint Medical Clinic'}>
            <img alt={'Logo'} src={logo} />
          </Link>
        </div>
        <div className={'get-started'}>
          <OutboundLink
            href='https://healthpoint.cortico.ca/appointment-booking'
            rel='noopener noreferrer'
            target={'_blank'}>
            Book Now!
          </OutboundLink>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
