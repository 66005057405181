import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactPage = () => (
  <Layout>
    <SEO title='Contact' />
    <div className={'container'}>
      <div className={'content'}>
        <div className={'title'}>
          <h1>Contact</h1>
        </div>

        <div className={'row'}>
          <div className={'col-7'}>
            <p>
              We take our commitment to our patients seriously. If you need help, or have
              any questions, please do not hesitate to contact us.
            </p>
            <h3>Address</h3>
            <a
              href='https://g.page/healthpoint?share'
              target={'_blank'}
              rel='noopener noreferrer'>
              301 - 20055 Willowbrook Dr., Langley, BC
            </a>
          </div>

          <div className={'col-5'}>
            <div className={'contact-items'}>
              <p>Plain email or good ol' phone is the best way to contact us.</p>
              <h3>
                <a href='mailto: frontdesk@hpmedical.ca'>frontdesk@hpmedical.ca</a>
                <br />
                <a href='tel:123-456-7890'>(604) 546 – 1292</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
