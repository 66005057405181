import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { StaticQuery, graphql, Link } from 'gatsby';
import Header from './header';
import '../../static/styles/main.scss';
import logo from '../../static/images/logo.svg';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-5'}>
                <div className={'widget__item'}>
                  <div className={'logo'}>
                    <OutboundLink
                      href='https://www.hpmedical.ca/'
                      title={'HealthPoint Medical Clinic'}
                      target={'_blank'}
                      rel='noopener noreferrer'>
                      <img alt={'HealthPoint Logo'} src={logo} />
                    </OutboundLink>
                    <p>
                      Building a healthy community <br />
                      one family at a time
                    </p>
                  </div>

                  <div className={'about'}>
                    <h3>Walk-in hours are Monday to Friday 9AM-2PM</h3>
                    <a
                      href='https://g.page/healthpoint?share'
                      target={'_blank'}
                      rel='noopener noreferrer'>
                      301 - 20055 Willowbrook Dr., Langley, BC
                    </a>
                  </div>
                </div>
              </div>

              <div className={'col-2'}>
                <div className={'widget__item'}>
                  <ul className={'links'}>
                    <h4>HealthPoint</h4>
                    <ul>
                      <li>
                        <OutboundLink
                          href={'https://www.hpmedical.ca/become_a_patient'}
                          target={'_blank'}
                          rel='noopener noreferrer'>
                          New Patients
                        </OutboundLink>
                      </li>
                      <li>
                        <OutboundLink
                          href={'https://www.hpmedical.ca/about'}
                          target={'_blank'}
                          rel='noopener noreferrer'>
                          About
                        </OutboundLink>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>

              <div className={'col-2'}>
                <div className={'widget__item'}>
                  <div className={'links'}>
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <Link to='/contact' title={'Contact Us'}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={'copyright'}>
              <p>
                Copyright {new Date().getFullYear()}, {` `}{' '}
                <OutboundLink
                  href='https://hpmedical.ca'
                  title={'HealthPoint Medical Clinic Website'}
                  target={'_blank'}
                  rel='noopener noreferrer'>
                  HealthPoint
                </OutboundLink>
                . All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
