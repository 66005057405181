import React from 'react';
import { Container } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import featureImage from '../../static/images/WaitingRoom.svg';
import thumbnailNav from '../../static/images/Navigate.svg';
import thumbnailBooking from '../../static/images/BookAnAppointment.svg';
import thumbnailSignUp from '../../static/images/SignUp.svg';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const IndexPage = () => (
  <Layout>
    <SEO title='Book Online' />

    <div className={'page-header home'}>
      <h2>See a Doctor Today</h2>
      <p>
        Skip The Waiting Room, Book Your Appointment Online
        <br />
        In Three Easy Steps
      </p>
      <Container maxWidth='md'>
        <img alt={'Waiting Room'} src={featureImage} />
      </Container>
    </div>

    <div className={'container'}>
      <div className={'features'}>
        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Sign Up'} src={thumbnailSignUp} />
              </div>
            </div>

            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Sign up for online booking</h2>
                <p>
                  If you are new to our clinic, please use our online booking system to
                  sign up.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Book your visit</h2>
                <p>
                  Choose from our available appointments to book a visit that’s convenient
                  for you. Please make sure to fill "reason for visit" section so that our
                  staff can make any necessary preparations in advance.
                </p>
              </div>
            </div>

            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Calendar'} src={thumbnailBooking} />
              </div>
            </div>
          </div>
        </div>

        <div className={'feature__item'}>
          <div className={'row'}>
            <div className={'col-6 first'}>
              <div className={'thumbnail'}>
                <img alt={'Navigation'} src={thumbnailNav} />
              </div>
            </div>

            <div className={'col-6'}>
              <div className={'feature__content'}>
                <h2>Visit our clinic </h2>
                <p>
                  We kindly ask you to show up 5 minutes early, so that you see your
                  doctor right away!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Book Online Now!</h2>
          <p>Skip the wait and book online. See your doctor today!</p>
        </div>

        <div className={'button'}>
          <OutboundLink
            href='https://healthpoint.cortico.ca/appointment-booking'
            rel='noopener noreferrer'
            target={'_blank'}>
            Book Now!
          </OutboundLink>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
